import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TeamsMeetingLinkLocator } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential, CommunicationUserIdentifier } from '@azure/communication-common';
import {
  CallAndChatLocator,
  CallWithChatComposite,
  COMPOSITE_LOCALE_CS_CZ,
  useAzureCommunicationCallWithChatAdapter,
  CallWithChatCompositeOptions,
  darkTheme,
  DEFAULT_COMPOSITE_ICONS,
  
} from '@azure/communication-react';
import { Theme, PartialTheme, Spinner } from '@fluentui/react';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import './App.css';


/**
 * Authentication information needed for your client application to use
 * Azure Communication Services.
 *
 * For this quickstart, you can obtain these from the Azure portal as described here:
 * https://docs.microsoft.com/en-us/azure/communication-services/quickstarts/identity/quick-create-identity
 *
 * In a real application, your backend service would provide these to the client
 * application after the user goes through your authentication flow.
 */

const ENDPOINT_URL = 'https://communicationservicessdas.europe.communication.azure.com/';

let locale = COMPOSITE_LOCALE_CS_CZ;
locale.strings.call.lobbyScreenConnectingToCallTitle = "Schůzka se připravuje" // By default it is "Joining call"
locale.strings.call.lobbyScreenConnectingToCallMoreDetails = "" // By default it is ""
locale.strings.call.lobbyScreenWaitingToBeAdmittedTitle = "Ještě chvilku, až schůzka začne, operátor vás ihned přijme." // By default it is "Waiting to be admitted"
locale.strings.call.lobbyScreenWaitingToBeAdmittedMoreDetails = ""  // By default it is ""
let icons = DEFAULT_COMPOSITE_ICONS;

export type CallWithChatExampleProps = {
  // Props needed for the construction of the CallWithChatAdapter
  userId: CommunicationUserIdentifier;
  token: string;
  displayName: string;
  endpointUrl: string;
  locator: TeamsMeetingLinkLocator | CallAndChatLocator;

  // Props to customize the CallWithChatComposite experience
  fluentTheme?: PartialTheme | Theme;
  compositeOptions?: CallWithChatCompositeOptions;
  callInvitationURL?: string;
};

export const CallWithChatExperience = (props: CallWithChatExampleProps): JSX.Element => {
  // Construct a credential for the user with the token retrieved from your server. This credential
  // must be memoized to ensure useAzureCommunicationCallWithChatAdapter is not retriggered on every render pass.
  const credential = useMemo(() => new AzureCommunicationTokenCredential(props.token), [props.token]);

  // Create the adapter using a custom react hook provided in the @azure/communication-react package.
  // See https://aka.ms/acsstorybook?path=/docs/composite-adapters--page for more information on adapter construction and alternative constructors.
  const adapter = useAzureCommunicationCallWithChatAdapter({
    userId: props.userId,
    displayName: props.displayName,
    credential,
    locator: props.locator,
    endpoint: props.endpointUrl,
  });

  const formFactor = isMobile ? 'mobile' : 'desktop';

  // The adapter is created asynchronously by the useAzureCommunicationCallWithChatAdapter hook.
  // Here we show a spinner until the adapter has finished constructing.
  if (!adapter) {
    return <Spinner label="Schůzka se připravuje..." />;
  }

  return <CallWithChatComposite locale={COMPOSITE_LOCALE_CS_CZ} adapter={adapter} fluentTheme={darkTheme} options={props.compositeOptions} formFactor={formFactor} icons={icons}/>;
};

/**
 * Entry point of your application.
 */
function App(): JSX.Element {
  const [userId, setUserId] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [teamsMeetingLink, setTeamsMeetingLink] = useState<string>('');
  let queryParameters = new URLSearchParams(window.location.search)
  let externalUserId = queryParameters.get("user-id");
  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.post(`https://votz.cez.cz/api/externalperson/access`,
        {
          token: `${process.env.REACT_APP_API_KEY}`,
          id: externalUserId
        },
        {
          headers: { 
          "Content-Type": "application/problem+json; charset=utf-8"
        }}
    ).then(res => {
        setToken(res.data.token);
        setTeamsMeetingLink(decodeURIComponent(res.data.teamsJoinUrl));
        setDisplayName(res.data.externalPersonName);
        setUserId(res.data.identityId);
    });
  } catch(error) {
    setToken('err');
  }};

    fetchData();
  });
  
  if(token && token !== '' && token !== undefined && token !== 'err'){
    return (
      <CallWithChatExperience 
        userId = {{ "communicationUserId": userId }}
        token={ token }
        displayName = { displayName }
        endpointUrl={ ENDPOINT_URL }
        locator = { {"meetingLink": teamsMeetingLink} }
      />
    );
  }
  else if(token === undefined || token === 'err'|| !externalUserId || externalUserId === undefined){
    return(<div className='err-message'><div className='err-message-label'>Vaše schůzka nebyla nalezena.</div></div>)
  }    
  else{
  return <Spinner label="Schůzka se vyhledává..." />;
}
}

export default App;